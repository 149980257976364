import React, { Suspense, useEffect, useState } from 'react';
import { Heading, Pane, Paragraph, SideSheet, toaster, TextInput, majorScale, Combobox } from 'evergreen-ui';
import { createNewAccount, getGroups } from '../../api/shared';
import errorHandler from "../errorMessages/errorHandler";
import CountryPicker from "./CountryPicker";

type Country = {
    countryCode: string;
    countryName: string;
    dialCode: string;
    flag: string;
};

interface GroupOption {
    label: string;
    value: string;
};

const CreateNewAccount:React.FC <{
    isOpen: boolean;
    onClose: () => void;
  }> = ({isOpen, onClose}) => {
    const [groups, setGroups] = useState<GroupOption[]>([]);
    const [selectedGroup, setSelectedGroup] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [country, setCountry] = useState<Country>({ countryCode: 'US', countryName: 'United States', dialCode: '+1', flag: '🇺🇸' });
  
    useEffect(() => {
      fetchGroups();
    }, [isOpen]);
  
    const handleCreateSave = async (
        name: string,
        selectedGroup: string,
        country: Country
      ) => {
        // Remove any non-numeric characters from 'name'
        const numericName = name.replace(/\D/g, '');
      
        if (numericName.length <= 12 && numericName.length >= 10) {
          toaster.danger(
            'Account name must contain 10 - 12 digits',
            { duration: 8 }
          );
          return;
        }
      
        const fullAccountID = `${country.dialCode}${numericName}`;
      
        try {
          await createNewAccount({
            accountId: fullAccountID,
            group: selectedGroup,
          });
          toaster.success('Account created successfully', { duration: 8 });
          onClose();
        } catch (error: any) {
          errorHandler(error);
          console.error('Error creating account', error);
          toaster.danger(error.detail, { duration: 10 });
        }
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setName(event.target.value);
    };
  
    const fetchGroups = async () => {
      try {
        const groupNames = await getGroups();
        const formattedGroups = groupNames.map(group => ({ label: group, value: group }));
        setGroups(formattedGroups);
      } catch (error) {
        console.error("Error fetching groups:", error);
      }
    };
  
    return(
      <SideSheet
        isShown={isOpen}
        onCloseComplete={onClose}
        preventBodyScrolling
      >
        <Pane padding={16} borderBottom="muted">
          <Heading size={600} marginBottom={majorScale(2)}>Create New Account</Heading>
        </Pane>
        {
          <React.Fragment>
            <Pane padding={16}>
              <Paragraph><strong>Account ID:</strong></Paragraph>
              <Pane style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', marginBottom: '16px'}}>
                <CountryPicker
                  selectedCountry={country}
                  onSelect={(selectedCountry) => {
                    setCountry(selectedCountry);
                  }}
                />
                <TextInput
                  name="account-id"
                  placeholder="Enter Account ID"
                  value={name}
                  onChange={handleNameChange}/>
              </Pane>
            </Pane>
            <Pane padding={16}>
              <Paragraph><strong>Group:</strong></Paragraph>
              <Suspense fallback={<div>Loading...</div>}>
                <Combobox
                  items={groups}
                  itemToString={(item) => item ? item.label : ""}
                  onChange={(selected) => setSelectedGroup(selected ? selected.value : "")}
                  placeholder="Select group..."
                />
              </Suspense>
            </Pane>
            <Pane padding={16}>
              <button
                className="button-green button-padding"
                onClick={() => {
                  handleCreateSave(name, selectedGroup, country)
                }}
              >
                Save
              </button>
              <button
                className="button-red button-padding"
                onClick={onClose}
              >
                Cancel
              </button>
            </Pane>
          </React.Fragment>
        }
      </SideSheet>
    )
  };

export default CreateNewAccount;