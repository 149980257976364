import { addRefreshDataToStorage, authUserInStorage, getAccessToken, checkAndRenewAccessToken, addRefreshToken} from '../utils/auth';
import { endpoints } from './endpoints';
import { apiHost } from '../constants/api';
import handleError from '../ui/errorMessages/errorHandler';
import superagent from 'superagent';
import hash from 'hash.js';
import { Buffer } from 'buffer';
import { RootGroup, GroupUpdateModel } from '../models/models';
import { group } from 'console';

export function base64HashedPassword(str: string): string {
  const hashed = hash.sha384().update(str).digest('hex');
  return Buffer.from(hashed, 'hex').toString('base64');
}

export function login(username: string, password: string) {
  const body = new URLSearchParams({
    "username": username,
    "password": base64HashedPassword(password),
  });
  //const userData = new FormData();
  //userData.append('username', username);
  //userData.append('password', base64HashedPassword(password));
  return superagent.post(apiHost + endpoints.userAuthenticate)
                   //.set('Content-Type', 'application/x-www-form-urlencoded')
                   .send(body.toString())
                   .then((response) => {
    const body = JSON.parse(response.text);
    authUserInStorage(body.access_token);
    addRefreshToken(body.refresh_token);
    return body;
  }).catch((error) => {
    handleError(error);
  });
}

export async function getGroupData(selectedGroup: string): Promise<any>{
  await checkAndRenewAccessToken();
  const token = getAccessToken();
  try {
    const response = await superagent
      .get(apiHost + endpoints.aggregateReportSingle + '?configTag=' + selectedGroup)
      .set('Authorization', `Bearer ${token}`);
      return JSON.parse(response.text);
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export async function getGroups(): Promise<string[]> {
  await checkAndRenewAccessToken();
  const token = getAccessToken();
  try {
    const response = await superagent
      .get(apiHost + endpoints.groupNames)
      .set('Authorization', `Bearer ${token}`);
    return JSON.parse(response.text);
  } catch (error) {
    handleError(error);
    return [];
  }
}

export async function getRootGroups(): Promise<RootGroup[]> {
  await checkAndRenewAccessToken();
  const token = getAccessToken();
  try {
    const response = await superagent
      .get(apiHost + endpoints.groupRoot)
      .set('Authorization', `Bearer ${token}`);
    return JSON.parse(response.text);
  } catch (error) {
    handleError(error);
    return [];
  }
}

export async function getRootAccounts(): Promise<RootGroup[]> {
  await checkAndRenewAccessToken();
  const token = getAccessToken();
  try {
    const response = await superagent
      .get(apiHost + endpoints.accountsRoot)
      .set('Authorization', `Bearer ${token}`);
    return JSON.parse(response.text);
  } catch (error) {
    handleError(error);
    return [];
  }
}

export async function getGroupInfo(groupName: string): Promise<RootGroup[]> {
  await checkAndRenewAccessToken();
  const token = getAccessToken();
  try {
    const response = await superagent
      .get(apiHost + endpoints.groupRoot + groupName)
      .set('Authorization', `Bearer ${token}`);
    return JSON.parse(response.text);
  } catch (error) {
    handleError(error);
    return [];
  }
}

export async function updateAccountGroup(accountId: string, newGroup: string, setEnabled: boolean) {
  await checkAndRenewAccessToken();
  const token = getAccessToken();
  try {
    const updatedData = {
      group: newGroup,
      enabled: setEnabled
    };
    const response = await superagent
      .put(apiHost + endpoints.accountsRoot + accountId)
      .set('Authorization', `Bearer ${token}`)
      .send(updatedData);
    return response.body;
  } catch (error) {
    console.error('Error updating account group:', error);
    throw error; // Re-throw the error so it can be handled by the caller
  }
}

export async function updateGroup(groupDetails: GroupUpdateModel) {
  await checkAndRenewAccessToken();
  const token = getAccessToken();
  const { oldName, groupData } = groupDetails;
  
  try {
    const updatedData: RootGroup = {
      name: groupData.name || '',
      bypassWiFiTraffic: groupData.bypassWiFiTraffic,
      levelOfSavings: groupData.levelOfSavings,
      shapingData: groupData.shapingData
    };
    const response = await superagent
      .put(apiHost + endpoints.groupRoot + oldName)
      .set('Authorization', `Bearer ${token}`)
      .send(updatedData);
    return response.body;
  } catch (error) {
    console.error('Error updating group:', error);
  }
}

export async function createGroup(groupDetails: RootGroup) {
  await checkAndRenewAccessToken();
  const token = getAccessToken();
  const updatedData: RootGroup = {
    name: groupDetails.name,
    bypassWiFiTraffic: groupDetails.bypassWiFiTraffic,
    levelOfSavings: groupDetails.levelOfSavings,
    shapingData: groupDetails.shapingData
  };
  const url = apiHost + endpoints.groupRoot;
  const response = await superagent
    .post(url)
    .set('Authorization', `Bearer ${token}`)
    .send(updatedData);
  return response.body;
}

export async function importAccounts(csvFile: File): Promise<any> {
  await checkAndRenewAccessToken();
  const token = getAccessToken();
  try {
    const formData = new FormData();
    formData.append('file', csvFile);
    const response = await superagent
      .post(apiHost + endpoints.accountsImport)
      .set('Authorization', `Bearer ${token}`)
      .send(formData);
    // return JSON.parse(response.text);
  } catch (error: any) {
    if (error.response && error.response.text) {
    throw JSON.parse(error.response.text);
    } else if (error.message) {
      throw new Error(error.message);
    } else {
      throw new Error('Unknown error');
    }
  }
}

export async function createNewAccount(accountData: {
  accountId: string;
  group: string;
}): Promise<any> {
  await checkAndRenewAccessToken();
  const token = getAccessToken();
  try {
    const response = await superagent
      .post(apiHost + endpoints.accountsRoot)
      .set('Authorization', `Bearer ${token}`)
      .send(accountData);
    return response.body;
  } catch (error: any) {
    throw error.response.body;
  }
}

export async function getDeviceCount(groupName: string): Promise<number> {
  await checkAndRenewAccessToken();
  const token = getAccessToken();
  try {
    const response = await superagent
      .get(apiHost + endpoints.deviceCount + "?groupName=" + groupName)
      .set('Authorization', `Bearer ${token}`);
    return Number(response.text);
  } catch (error) {
    handleError(error);
  }
  return 0;
}

export async function getInvite(): Promise<any> {
  await checkAndRenewAccessToken();
  const token = getAccessToken();
  try {
    const response = await superagent
      .get(apiHost + endpoints.invitationRoot)
      .set('Authorization', `Bearer ${token}`);
    return JSON.parse(response.text);
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export async function updateInvite(invite: string): Promise<any> {
  await checkAndRenewAccessToken();
  const token = getAccessToken();
  try {
    const response = await superagent
      .put(apiHost + endpoints.invitationRoot)
      .set('Authorization', `Bearer ${token}`)
      .send({"invite": invite});
    return response.body;
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export async function resendText(numbers: any): Promise<any> {
  await checkAndRenewAccessToken();
  const token = getAccessToken();
  try {
    const response = await superagent
      .put(apiHost + endpoints.resendOnboardingNotification)
      .set('Authorization', `Bearer ${token}`)
      .send(numbers);
    return response.text;
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export async function updateProvAccount(
  accountId: string,
  bypassWiFiTraffic: boolean,
  group: string,
  roaming: boolean,
): Promise<any> {
  await checkAndRenewAccessToken();
  const token = getAccessToken();
  try {
    const encodedAccountId = encodeURIComponent(accountId);
    const sendData = {
      bypassWiFiTraffic,
      group,
      roaming,
      accountId,
    };
    const response = await superagent
      .put(`${apiHost}${endpoints.updateProvAccount}${encodedAccountId}`)
      .set('Authorization', `Bearer ${token}`)
      .send(sendData);
    return response.body;
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export async function changePassword(password: string, newPassword: string) {
  await checkAndRenewAccessToken();
  const token = getAccessToken();
  try {
    const userData = new FormData();
    userData.append('currentPassword', base64HashedPassword(password));
    userData.append('newPassword', base64HashedPassword(newPassword));
    const response = await superagent
      .put(apiHost + endpoints.userChangePassword)
      .set('Authorization', `Bearer ${token}`)
      .send(userData);
    return response.body;
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export async function getRemoteConfig(configName: string): Promise<any> {
  await checkAndRenewAccessToken();
  const token = getAccessToken();
  try {
    const response = await superagent
      .get(apiHost + endpoints.remoteConfig + '?configName=' + configName)
      .set('Authorization', `Bearer ${token}`);
    return JSON.parse(response.text);
  } catch (error) {
    handleError(error);
    throw error;
  }
}