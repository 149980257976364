import React, { useEffect, useState, Suspense, } from "react";
import { Dialog, Pane, TextareaField, toaster } from "evergreen-ui";
import { getInvite, updateInvite } from "../../api/shared";

const EditInviteDialog: React.FC <{
    isOpen: boolean;
    onClose: () => void;
  }> = ({isOpen, onClose}) => {
  
    const [invite, setInvite] = useState<string>('');
    const [newInvite, setNewInvite] = useState<string>('');
  
    useEffect(() => {
      fetchInvite();
    }, []);
  
    const fetchInvite = async () => {
      try {
        const invite = await getInvite();
        setInvite(invite.invite);
      } catch (error) {
        console.error("Error fetching invite:", error);
      }
    };
  
    const handleDialogSave = async (newInvite: string) => {
      try {
        await updateInvite(newInvite);
        toaster.success("Invite updated successfully", { duration: 8 });
        onClose();
      } catch (error) {
        console.error("Error updating invite:", error);
        toaster.danger("Failed to update invite", { duration: 10 });
      }
    }
  
    return (
      <Pane>
        <Dialog
          isShown={isOpen}
          title="Edit Invitation"
          onCloseComplete={onClose}
          confirmLabel="Save"
          onConfirm={() => {
            handleDialogSave(newInvite);
          }}
        >
          <Suspense fallback={<div>Loading...</div>}>
            <Pane>
              <TextareaField
              name='Invite Textarea'
              placeholder='Enter new invite text'
              value={newInvite || invite}
              onChange={(e:any) => setNewInvite(e.target.value)} />
            </Pane>
          </Suspense>
        </Dialog>
      </Pane>
    );
  }

export default EditInviteDialog;