import React, { useEffect, useState } from "react";
import {
  getRootGroups,
  getGroupInfo,
  updateGroup,
  createGroup,
  getRemoteConfig,
} from "../../api/shared";
import { RootGroup, shapingData, shapingValue } from "../../models/models";
import { errorHandler } from "../errorMessages/errorHandler";
import { toaster } from "evergreen-ui";
import {
  Pane,
  Table,
  majorScale,
  SideSheet,
  Heading,
  Paragraph,
  TextInput,
  Switch,
  Text,
  Combobox,
  Pagination,
} from "evergreen-ui";
import "./styles.css";
import {
  defaultShapingLevel,
  ShapingLevel,
  shapingLevels,
  toShapingLevel,
} from "../../models/ShapingLevel";
import AdvSettings from "./advanced";
import LoadingWord from "../loader/loadingWord";

//Defining a type for the AdvSettings so we can manage them from this level
type AdvSettingsState = {
  isShown: boolean;
  lowBitrate?: number;
  lowDataRate?: number;
  mediumBitrate?: number;
  mediumDataRate?: number;
  highBitrate?: number;
  highDataRate?: number;
  noneBitrate?: number;
  noneDataRate?: number;
};

//This is the default group that will be created when the create button is clicked
const newGroup: RootGroup = {
  name: "",
  bypassWiFiTraffic: true,
  levelOfSavings: defaultShapingLevel.name,
  shapingData: {}, // Ensure shapingData is initialized
};

const getRemoteConfigData = async () => {
  try {
    const response = await getRemoteConfig("domainsConfigurationV3");
    let data;
    if (typeof response === "string") {
      data = JSON.parse(response);
    } else {
      data = response;
    }

    // Extract servicesList from data.domains
    const servicesList = data.domains || [];

    // Build shapingValuesConfig from data.domains
    const shapingValuesConfig: { [serviceName: string]: ServiceShapingValues } = {};

    servicesList.forEach((service: any) => {
      const serviceName = service.name;
      // Ensure rateLimit exists
      if (service.rateLimit) {
        shapingValuesConfig[serviceName] = {
          low: {
            target: service.rateLimit.low || 0,
            targetDataRate: service.rateLimit.lowDataRate || 0,
          },
          medium: {
            target: service.rateLimit.medium || 0,
            targetDataRate: service.rateLimit.mediumDataRate || 0,
          },
          high: {
            target: service.rateLimit.high || 0,
            targetDataRate: service.rateLimit.highDataRate || 0,
          },
          none: {
            target: service.rateLimit.none || 0,
            targetDataRate: service.rateLimit.noneDataRate || 0,
          },
        };
      }
    });

    return { servicesList, shapingValuesConfig };
  } catch (error) {
    console.error("Error fetching remote config data:", error);
    return null;
  }
};


const itemsPerPage = 10;

type ServiceShapingValues = {
  low: shapingValue;
  medium: shapingValue;
  high: shapingValue;
  none: shapingValue;
};

const GroupRootList: React.FC = () => {
  // State variables
  const [loading, setLoading] = useState<boolean>(true);
  const [rootData, setRootData] = useState<RootGroup[]>([]);
  const [isSideSheetOpen, setIsSideSheetOpen] = useState<boolean>(false);
  const [selectedGroup, setSelectedGroup] = useState<RootGroup | null>(null);
  const [newGroupName, setNewGroupName] = useState<string>("");
  const [bypassWiFiTraffic, setBypassWiFiTraffic] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [servicesList, setServicesList] = useState<any[]>([]);
  const [shapingValuesConfig, setShapingValuesConfig] = useState<any>({});
  const [selectedService, setSelectedService] = useState<string | null>(null);
  const [serviceShapingLevels, setServiceShapingLevels] = useState<{
    [serviceName: string]: ShapingLevel;
  }>({});
  const [serviceShapingValues, setServiceShapingValues] = useState<{
    [serviceName: string]: ServiceShapingValues;
  }>({});
  const [advSettingsState, setAdvSettingsState] = useState<AdvSettingsState>({
    isShown: false,
  });

  //This function is used to reset the shaping data when the user saves all changes to a group
  const resetShapingData = () => {
    setServiceShapingValues({});
  };

  useEffect(() => {
    if (selectedService) {
      const shapingValues = serviceShapingValues[selectedService] || {
        low: { target: 0, targetDataRate: 0 },
        medium: { target: 0, targetDataRate: 0 },
        high: { target: 0, targetDataRate: 0 },
        none: { target: 0, targetDataRate: 0 },
      };
      setAdvSettingsState((prevState) => ({
        ...prevState,
        lowBitrate: shapingValues.low.target,
        lowDataRate: shapingValues.low.targetDataRate,
        mediumBitrate: shapingValues.medium.target,
        mediumDataRate: shapingValues.medium.targetDataRate,
        highBitrate: shapingValues.high.target,
        highDataRate: shapingValues.high.targetDataRate,
        noneBitrate: shapingValues.none.target,
        noneDataRate: shapingValues.none.targetDataRate,
      }));
    }
  }, [selectedService]);

  //This function is called when the page is loaded and it grabs the group data.
  useEffect(() => {
    fetchData();
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        event.preventDefault();
        setIsSideSheetOpen(false);
      }
    };

    window.addEventListener("keydown", handleEsc);
    return () => window.removeEventListener("keydown", handleEsc);
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const data: RootGroup[] = await getRootGroups();
      setRootData(data);
      const remoteConfig = await getRemoteConfigData();
      if (remoteConfig) {
        setServicesList(remoteConfig.servicesList || []);
        setShapingValuesConfig(remoteConfig.shapingValuesConfig || {});
      }
    } catch (error) {
      errorHandler(error);
      console.error("Error getting groups", error);
    } finally {
      setLoading(false);
    }
  };

  const allShapingLevels: ShapingLevel[] = [
    { name: "low", label: "Low" },
    { name: "medium", label: "Medium" },
    { name: "high", label: "High" },
    { name: "none", label: "None" },
  ]
  
  //when we are ready to use high and meduim shaping levels just add them here
  const availableShapingLevelNames = ['none', 'low'];
  
  let shapingLevels = allShapingLevels.filter(level => 
    availableShapingLevelNames.includes(level.name)
  );
  
  const currentShapingLevel = serviceShapingLevels[selectedService!];

  if (
    currentShapingLevel &&
    !availableShapingLevelNames.includes(currentShapingLevel.name)
  ) {
    shapingLevels = [...shapingLevels, currentShapingLevel];
  }

  //This function updates shaping data when a group is selected
  const updateShapingData = (group: RootGroup | null) => {
    let newServiceShapingLevels: { [serviceName: string]: ShapingLevel } = {};
    let newServiceShapingValues: { [serviceName: string]: ServiceShapingValues } = {};

    servicesList.forEach((service) => {
      const serviceName = service.name;

      const shapingLevel =
        group?.shapingData?.[serviceName]?.target
        ? toShapingLevel(group.shapingData[serviceName].target as string) || defaultShapingLevel
        : defaultShapingLevel;

      newServiceShapingLevels[serviceName] = shapingLevel;

      newServiceShapingValues[serviceName] = {
        low: group?.shapingData?.[serviceName]?.low || shapingValuesConfig[serviceName]?.low || { target: 0, targetDataRate: 0 },
        medium: group?.shapingData?.[serviceName]?.medium || shapingValuesConfig[serviceName]?.medium || { target: 0, targetDataRate: 0 },
        high: group?.shapingData?.[serviceName]?.high || shapingValuesConfig[serviceName]?.high || { target: 0, targetDataRate: 0 },
        none: group?.shapingData?.[serviceName]?.none || shapingValuesConfig[serviceName]?.none || { target: 0, targetDataRate: 0 },
      };
    });

    setServiceShapingLevels(newServiceShapingLevels);
    setServiceShapingValues(newServiceShapingValues);
  };

  const handleRowClick = async (rowData: RootGroup) => {
    setNewGroupName(rowData.name || "");
    setBypassWiFiTraffic(rowData.bypassWiFiTraffic || false);
    setShapingLevels(rowData);
    setSelectedService(null);
    resetShapingData();

    try {
      const data = await getGroupInfo(rowData.name);
      setSelectedGroup(rowData);
      updateShapingData(rowData);
    } catch (error) {
      toaster.warning("Error", {
        description: "Error getting group data",
        duration: 8,
      });
      console.error("Error getting group data", error);
    } finally {
      setIsSideSheetOpen(true);
    }
  };

  const handleCreateClick = async (newGroup: RootGroup) => {
    resetState();
    setNewGroupName(newGroup.name);
    setBypassWiFiTraffic(newGroup.bypassWiFiTraffic);
    setSelectedGroup(newGroup);
    setShapingLevels(newGroup);
    updateShapingData(null); // Initialize shaping data for a new group
    setIsSideSheetOpen(true);
  };

  const setShapingLevels = (group: RootGroup) => {
    let newServiceShapingLevels: { [serviceName: string]: ShapingLevel } = {};
    if (group.shapingData) {
      for (const serviceName in group.shapingData) {
        const shapingLevel =
          toShapingLevel(group.shapingData[serviceName]?.target as string) ||
          defaultShapingLevel;
        newServiceShapingLevels[serviceName] = shapingLevel;
      }
    } else {
      // Initialize shaping levels for a new group
      servicesList.forEach((service) => {
        const serviceName = service.name;
        newServiceShapingLevels[serviceName] = defaultShapingLevel;
      });
    }
    setServiceShapingLevels(newServiceShapingLevels);
  };

  //This save function will save the values of the group settings including shaping values
  const handleSave = async () => {
    if (selectedGroup) {
      const formData: RootGroup = {
        name: newGroupName || "",
        bypassWiFiTraffic: bypassWiFiTraffic,
        levelOfSavings: selectedGroup.levelOfSavings,
        shapingData: {}, // Initialize shapingData here
      };

      servicesList.forEach((service) => {
        const serviceName = service.name;
        formData.shapingData[serviceName] = {
          target: serviceShapingLevels[serviceName]?.name || defaultShapingLevel.name,
          ...serviceShapingValues[serviceName],
        };
      });

      // Build shapingData from serviceShapingLevels and serviceShapingValues
      for (const serviceName in serviceShapingLevels) {
        formData.shapingData![serviceName] = {
          target: serviceShapingLevels[serviceName].name,
          ...serviceShapingValues[serviceName],
        };
      }

      //This is the try catch block that will try and send the group data to the server
      try {
        if (selectedGroup.name) {
          await updateGroup({
            oldName: selectedGroup.name,
            groupData: formData,
          });
        } else {
          await createGroup(formData);
        }
        toaster.success("Group updated successfully", { duration: 8 });
        fetchData();
        setIsSideSheetOpen(false);
        resetShapingData();
      } catch (error: any) {
        if (error.response) {
          switch (error.response.status) {
            case 422:
              toaster.warning("Invalid entry", {
                description:
                  "One or more fields are invalid. Please check and try again.",
                duration: 8,
              });
              break;
            case 409:
              toaster.warning("Invalid entry", {
                description: "Group name already exists",
                duration: 8,
              });
              break;
            default:
              toaster.danger("Error updating group", {
                description: error.response.data.message,
                duration: 8,
              });
          }
        } else {
          errorHandler(error);
        }
      }
    }
  };

  const resetState = () => {
    setSelectedGroup(null);
    setNewGroupName("");
    setBypassWiFiTraffic(false);
    setSelectedService(null);
    setServiceShapingLevels({});
    setServiceShapingValues({});
    setAdvSettingsState({
      isShown: false,
      lowBitrate: undefined,
      lowDataRate: undefined,
      mediumBitrate: undefined,
      mediumDataRate: undefined,
      highBitrate: undefined,
      highDataRate: undefined,
      noneBitrate: undefined,
      noneDataRate: undefined,
    });
  };

  const handleSearch = (value: string) => {
    setSearchQuery(value);
    setCurrentPage(1);
  };

  const filteredData = rootData.filter((group) =>
    group.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = filteredData.slice(startIndex, endIndex);

  if (loading) {
    return <LoadingWord />;
  }

  return (
    <Pane>
      <Pane
        display="flex"
        justifyContent="flex-end"
        marginBottom={majorScale(2)}
      >
        <button className="Btn" onClick={() => handleCreateClick(newGroup)}>
          <div className="sign">+</div>
          <div className="text">Create</div>
        </button>
      </Pane>
      {/*---------------------------------------------
        This is the table that displays the group data. It is a table that displays the group name, connection policy, and default shaping level.
        The table is also searchable and paginated.
      ---------------------------------------------*/}
      <Table className="table">
        <Table.Head className="table-header">
          <Table.SearchHeaderCell
            onChange={handleSearch}
            placeholder="Search groups..."
          />
          <Table.TextHeaderCell>Connection Policy</Table.TextHeaderCell>
          <Table.TextHeaderCell>Default Shaping Level</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {currentItems.map((group, index) => (
            <Table.Row
              className="table-row"
              key={index}
              isSelectable
              onSelect={() => handleRowClick(group)}
            >
              <Table.TextCell className="table-cell">{group.name}</Table.TextCell>
              <Table.TextCell className="table-cell">
                {group.bypassWiFiTraffic
                  ? "Cellular only"
                  : "Cellular and WiFi"}
              </Table.TextCell>
              <Table.TextCell className="table-cell">
                {group.levelOfSavings}
              </Table.TextCell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <Pagination
        page={currentPage}
        totalPages={Math.ceil(filteredData.length / itemsPerPage)}
        onPageChange={(page: number) => setCurrentPage(page)}
      />
      {/*---------------------------------------------
        This is the side sheet that pops up when a group is clicked. It displays the group details and allows the user to edit the group settings.
        The side sheet also has a save and cancel button.
      ---------------------------------------------*/}
      <SideSheet
        isShown={isSideSheetOpen}
        onCloseComplete={() => {
          setIsSideSheetOpen(false);
          resetState();
        }}
      >
        <Pane padding={16} borderBottom="muted">
          <Heading size={600}>Group Details</Heading>
        </Pane>
        <Pane padding={16}>
          {selectedGroup && (
            <>
              <Paragraph>
                <strong className="large_text">Name:</strong>
              </Paragraph>
              <TextInput
                value={newGroupName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setNewGroupName(e.target.value)
                }
                marginBottom={16}
              />
              <Paragraph>
                <strong className="large_text">Connection Policy:</strong>
              </Paragraph>
              <Pane display="flex" alignItems="center" marginBottom={16}>
                <Text color="muted">Cellular and WiFi</Text>
                {/* This is disabled so the user cannot change the value, to change this in the future remove the last line of the switch */}
                <Switch
                  checked={true}
                  onChange={() => {}}
                  height={24}
                  marginLeft={8}
                  marginRight={8}
                  disabled={true}
                />
                <Text>Cellular only</Text>
              </Pane>
              <Paragraph>
                <strong className="large_text">Default Shaping Level:</strong>{" "}
              </Paragraph>
            </>
          )}

          {/* Service selection */}
          {servicesList.length > 0 && (
            <Combobox
              items={servicesList.map((service) => service.name)}
              selectedItem={selectedService}
              onChange={(selected) => setSelectedService(selected)}
              placeholder="Select a service"
              marginBottom={16}
            />
          )}

          {/* Shaping level selection */}
          {selectedService && (
            <Pane>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Text
                  style={{ fontWeight: "semi-bold" }}
                  marginRight={8}
                  marginLeft={16}
                >
                  {selectedService}
                </Text>
                {/* Advanced settings button */}
                <AdvSettings
                  currentService={selectedService!}
                  advSettingsState={advSettingsState}
                  setAdvSettingsState={setAdvSettingsState}
                  setShapingValues={(newValues: ServiceShapingValues) => {
                    setServiceShapingValues((prevValues) => ({
                      ...prevValues,
                      [selectedService!]: newValues,
                    }));
                  }}
                />
              </div>
              <Combobox
                items={shapingLevels}
                selectedItem={currentShapingLevel}
                onChange={(selected) => {
                  if (selected.name === 'medium' || selected.name === 'high') {
                    toaster.warning('Shaping level not available', {
                      description: 'This shaping level is not available for this group',
                      duration: 8,
                    });
                    return;
                  }
                  setServiceShapingLevels({
                    ...serviceShapingLevels,
                    [selectedService!]: selected,
                  });
                }}
                itemToString={(item) => (item ? item.label : "")}
                placeholder="Select shaping level"
                marginLeft={8}
                marginBottom={16}
              />
            </Pane>
          )}
        </Pane>

        <Pane padding={16}>
          <button className="button-green button-padding" onClick={handleSave}>
            Save
          </button>
          <button
            className="button-red button-padding"
            onClick={() => setIsSideSheetOpen(false)}
          >
            Cancel
          </button>
        </Pane>
      </SideSheet>
    </Pane>
  );
};

export default GroupRootList;
